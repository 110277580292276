<template>
  <div class="content-view">
    <div class="content-view__wrapper">
      <Section limiter>
        <BreadCrumbs :params="{navigation, color: 'rgba(0,0,0,.58)'}"/>
        <h1 class="content-view__title">{{$new.title}}</h1>
        <div class="content-view__meta">
          <div class="content-view__meta-date">
            <Icon class="content-view__meta-icon"
              viewport="0 0 20 20"
              xlink="calender"/>
            {{$new.started_at
            ? $moment.unix($new.started_at).format(formatDate)
            : $moment.unix($new.created_at).format(formatDate)}}
          </div>
          <div class="content-view__meta-update">
            {{$new.place ? $new.place.address :$new.address}}
          </div>
          <div v-if="$new.cost"
            class="content-view__meta-update">{{ $t('news_view.cost') }}: {{$new.cost}}
          </div>
        </div>
        <div class="content-view__image-holder"
            v-if="$new.images">
          <img class="content-view__image"
            :src="$new.images[0]"/>
        </div>
        <p class="content-view__caption"
          v-if="$laptop">{{$new.annotation}}</p>
        <div class="content-view__main-text  html-insert"
          v-html="$new.description"/>
      </Section>
      <Section v-if="$new.images && $new.images.length > 1"
        :limiter="$mobile">
        <Slider :height="$laptop ? 450 : 180"
          :indentation="$laptop ? 30 : 8"
          :items="$new.images.filter((item, index) => index > 0)"
          class="content-view__slider"
          component="ImageComponent"
          :count="1"
          :limiter="$laptop"/>
      </Section>
      <Section v-if="isShowMap"
        limiter>
        <h2 class="content-view__on-map-title">{{ $t('news_view.map_event') }}</h2>
        <OnMap :params="{sizeMap: {
          width: '100%', height: '400px'},
          coords: $new.place ? $new.place.location :$new.location}"
          :places="$route.params.type === 'news' ? [$new.place] : null"
          :events="$route.params.type === 'event' ? [$new] : null"
          class="content-view__on-map"/>
      </Section>
      <Section limiter>
        <VideoYouTube class="content-view__video-you-tube"
          :params="{width: '730px', height: '400px', src: $new.youtube}"
          v-if="$new.youtube"/>
      </Section>
      <Section>
        <Author :params="$new.author"
          class="content-view__author"
          v-if="$new.author"/>
      </Section>
      <Section limiter>
        <GuestReviews
          :params="{title: `${$t('comments.comments')}`, button: `${$t('comments.leave')}`}"
          typePage="places"/>
      </Section>
      <Section limiter>
        <Share :url="`${$host}/events/${$route.params.id}`"
          :title="$new.title"
          :description="$new.annotation"/>
      </Section>
      <Section>
        <ThematicSlider :params="{array: $shuffle($news.data).slice(0, 3),
          component: 'NewsCard',
          count}">
          <template slot="thematic-slider-title">
            <h2 class="thematic-slider__title">{{ $t('news_view.news') }}</h2>
          </template>
          <template slot="thematic-slider-button">
            <Row align="center"
              class="thematic-slider__see-all">
              <router-link class="thematic-slider__see-all-text"
                :to="`${$locale.base}/news`">
                {{$laptop ? $t('home.watch_all.p_1') : ''}}
                {{ $t('home.watch_all.p_2') }}
              </router-link>
              <Icon class="thematic-slider__see-all-icon"
                viewport="0 0 20 20"
                :rotate="90"
                xlink="arrow-navigation"/>
            </Row>
          </template>
        </ThematicSlider>
      </Section>
<ModifiedBillboard/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventView',
  data() {
    return {
      formatDate: 'DD MMMM YYYY HH:mm',
    };
  },
  created() {
    this.$store.dispatch('GET_NEW_FROM_SERVER', this.$route.params.id);
    this.$store.dispatch('GET_NEWS_FROM_SERVER_WITH_PARAMS', { clear: true });
  },
  computed: {
    count() {
      let count;
      if (this.$laptop) count = 3;
      if (this.$tablet) count = 2;
      if (this.$mobile) count = 1;
      return count;
    },
    isShowMap() {
      let isShow = false;
      if (this.$route.params.type === 'news' && this.$new.place) {
        isShow = true;
      }
      if (this.$route.params.type === 'event' && (this.$new.place || this.$new.location)) {
        isShow = true;
      }
      return isShow;
    },
    badgeName() {
      const Badge = this.$badges.find((badge) => badge.id === this.$new.badge_id);
      return Badge ? Badge.title : '';
    },
    navigation() {
      return [{
        href: '/',
        title: this.$i18n.t('menu.main'),
      }, {
        href: this.$route.path.replace(`/${this.$route.params.id}`, ''),
        title: this.$i18n.t('menu.news'),
      }, {
        href: this.$route.path,
        title: this.$new.title,
      }];
    },
    url() {
      return this.$host + this.$route.path;
    },
  },
  methods: {
    svgImg() {
      if (!this.$badgesSVG.length || this.$new.badge_id === null) {
        return '<div></div>';
      }
      const div = document.createElement('div');
      div.innerHTML = this.$badgesSVG.find((svg) => svg.id === this.$new.badge_id).img;
      const svg = div.getElementsByTagName('svg')[0];
      svg.setAttribute('width', '32px');
      svg.setAttribute('height', '32px');
      div.getElementsByClassName('color-marker-symbol')
        .forEach((el) => {
          const element = el;
          const badgeIconSettings = this.$new.badge
            ? this.$new.badge.icon_settings
            : undefined;
          element.style.fill = badgeIconSettings
            ? badgeIconSettings.color
            : '#000000';
          element.style.opacity = badgeIconSettings
            ? badgeIconSettings.transparency
            : 1;
        });
      return div.innerHTML;
    },
  },
  beforeDestroy() {
    this.$store.commit('CLEAR_CONTENT');
  },
  watch: {
    $route() {
      this.$store.dispatch('GET_CONTENT_FROM_SERVER', {
        type: this.$route.params.type,
        id: this.$route.params.id,
        lang: this.$i18n.locale,
      });
    },
  },
};
</script>
